import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from "@mui/material/Grid";
import Switch from '@mui/material/Switch';

import FooterButton from '../components/FooterButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import SelectNight from '../components/SelectNight';
import StackHeader from '../components/StackHeader';
import StackMetadata from '../components/StackMetadata';
import StackNavigation from '../components/StackNavigation';
import StackNotes from '../components/StackNotesByDate';
import StackViewer from '../components/StackViewer';
import Table from '../components/Table';
import TargetButton from '../components/TargetButton';

import {
  magnitudeGetter,
  disposition,
  stackNavigator,
  floatToSexagesimal
} from '../utils';
import { useNightlySummary } from '../services/ztf';
import { useOutburstsByDate } from '../services/firebase';
import { useLayout, useStackIndex } from '../services/cookies';

function isPointIn(key, pointList) {
  const votes = (pointList || {})[key];
  return disposition(votes);
}

function formatUrl(text) {
  return (url) => (
    <Button
      color="primary"
      component="a"
      href={url}
    >
      {text}
    </Button>
  );
}

export function formatFlag(flag) {
  return [0, 1, 2, 3, 4, 5].map(val => flag & val && val)
    .filter(val => val !== 0)
    .join(',');
}

const magnitude = magnitudeGetter(5);

export default function Date({ allTargets, status, user, users, apiToken }) {
  const { date } = useParams();
  const [stackIndex, setStackIndex] = useStackIndex(date);
  const [layout, setLayout] = useLayout();
  const [stacks, setStacks] = React.useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const summary = useNightlySummary(date, apiToken);
  // const photTableURL = useDownloadableTable(phot);
  const outbursts = useOutburstsByDate(date, users);
  const stack = stacks.length ? stacks[stackIndex] : null;

  React.useEffect(() => {
    if (summary.isSuccess && summary.data.valid && summary.data.stacks.length)
      setStacks(summary.data.stacks
        // annotate with stack index
        .map((stack, i) => ({
          ...stack,
          index: i
        }))
      );
  }, [summary.data])

  React.useEffect(() => {
    document.title = "ZBrowser2 : " + (date || "Date");
  }, [date]);

  const stackNavigation = stackNavigator(stacks, stackIndex, setStackIndex, []);

  React.useEffect(() => {
    const basename = searchParams.get("stack");
    // if a specific stack is requested, we cannot display it until the stacks
    // are loaded
    if (basename && (stacks.length > 0)) {
      stackNavigation.viewByBasename(basename);
      setSearchParams({});
    }
  }, [searchParams, stacks]);

  const changeLayout = (event) => {
    event.target.checked ? setLayout("horizontal") : setLayout("vertical");
  };
  const itemSizes = (layout === "horizontal") ? { xs: 12, md: 6 } : { xs: 12 };

  const ostat3 = stacks.filter((stack) => Math.abs(stack.ostat) >= 3);

  const table = summary.isSuccess && summary.data.table.map((row) => {
    const { m, merr } = magnitude(row);
    const outburst = outbursts.confirmedTargets.has(row.objid) ? "Outburst!" : "";
    return { ...row, m, merr, outburst };
  });

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <SelectNight
          status={status}
          sx={{ display: 'flex', flexGrow: 1 }}
        />
        <FormControlLabel
          control={<Switch checked={layout === "horizontal"} onChange={changeLayout} />}
          label="Horizontal layout"
          sx={{ display: 'flex' }}
        />
      </Box>
      {date && summary.isLoading && <Container><CircularProgress /></Container>}
      {date && summary.error && <Alert severity="error" sx={{ my: 2 }}>{summary.error.message}</Alert>}
      {date && summary.isSuccess &&
        <List>
          <ListItem><ListItemText primary={`${summary.data.night.exposures} exposures`} /></ListItem>
          <ListItem><ListItemText primary={`${summary.data.night.quads} quads`} /></ListItem>
          <ListItem><ListItemText primary={`${summary.data.night.ntargets} targets observed`}
            secondary={`${summary.data.night.ntargetsLE20mag} with m(5") ≤ 20 mag`} /></ListItem>
          <ListItem>
            <ListItemText primary={
              <>
                <span>{`${ostat3.length} possible outburst or fading events:`}</span>
                {ostat3.map((stack, index) => (
                  <Button key={index} onClick={() => stackNavigation.view(stack)}>
                    {`${stack.desg} (${stack.ostat.toFixed(1)})`}
                  </Button>
                ))}
              </>
            }>
            </ListItemText>
          </ListItem>
        </List>
      }
      {stack &&
        <>
          <StackHeader target={stack.desg} stack={stack} apiToken={apiToken} align="center" sx={{ mb: 2 }} />
          <Grid container>
            <Grid item {...itemSizes}>
              <StackViewer
                stackIndex={stackIndex}
                stacks={stacks}
                align="center"
              />
            </Grid>
            <Grid item {...itemSizes}>
              <StackNavigation
                stackIndex={stackIndex}
                selectedStackIndices={[]}
                stacks={stacks}
                stackNavigation={stackNavigation}
                align="center"
              />
              <hr />
              <StackMetadata stack={stack} />
            </Grid>
          </Grid>
          <StackNotes
            allTargets={allTargets}
            date={date}
            user={user}
            users={users}
            stack={stack}
            stacks={stacks}
            stackNavigation={stackNavigation}
            outbursts={outbursts}
          />
        </>
      }
      <FooterButton>
        <Table
          columns={[
            {
              field: 'desg',
              label: 'Designation',
              width: 150,
              formatter: (desg, row) => <TargetButton desg={desg} />
            },
            {
              field: 'obsdate',
              label: 'Date',
              width: 200,
              date: true,
              formatter: (obsdate, row) =>
                <Button
                  color="primary"
                  onClick={() => stackNavigation.view(
                    stacks.find((stack) => stack.stackid === row.stackid)
                  )}
                >
                  {obsdate}
                </Button>
            },
            { field: 'programid', label: 'PID', numeric: true },
            { field: 'tmtp', label: 'T-Tp (days)', numeric: true, width: 100 },
            { field: 'ra', label: 'RA (hr)', formatter: (ra) => floatToSexagesimal(ra / 15, 1, false, false) },
            { field: 'dec', label: 'Dec (deg)', formatter: (dec) => floatToSexagesimal(dec) },
            { field: 'mu', label: 'μ ("/hr)', numeric: true },
            { field: 'eph_unc', label: 'Pos. unc (")', numeric: true },
            { field: 'rh', label: 'rh (au)', numeric: true },
            { field: 'delta', label: 'Δ (au)', numeric: true },
            { field: 'phase', label: 'phase (°)', numeric: true },
            { field: 'selong', label: 'solar elongation (°)', numeric: true },
            { field: 'trueanomaly', label: 'true anomaly (°)', numeric: true },
            { field: 'centroid_offset', label: 'Centroid offset (pix)', numeric: true },
            { field: 'vmag', label: 'V (mag)', numeric: true },
            { field: 'filter', label: 'Filter', numeric: true },
            { field: 'm', label: `m(5")`, width: 75, numeric: true },
            { field: 'merr', label: `σ(5")`, width: 75, numeric: true },
            { field: 'ostat', label: 'O', numeric: true },
            { field: 'outburst', label: "Outburst", width: 100 },
            { field: 'flag', label: 'Flag', width: 75, formatter: formatFlag },
            { field: 'centroid_offset', label: 'Centroid offset (pix)', width: 75, numeric: true },
            { field: 'mu', label: 'μ ("/hr)', width: 75, numeric: true },
            { field: 'eph_unc', label: 'Pos. unc (")', width: 75, numeric: true },
            { field: 'seeing', label: 'Seeing (")', width: 75, numeric: true },
            { field: "sciimg_url", label: "Sci img", width: 75, formatter: formatUrl("Sci") },
            { field: "diffimg_url", label: "Diff img", width: 75, formatter: formatUrl("Diff") }
          ]}
          rows={table}
        />
      </FooterButton>
    </>
  );
}